@import './keyframes.css';
@import './normalize.css';

#curtain {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100%;
  background: hotpink;
  transform: translate3d(100%, 0, 0);
  transform-origin: left top;
}

#container {
  margin-left: 20vw;
  padding: 20px;
}

#title {
  opacity: 0;
  margin: 0;
}

#curtain > svg {
  width: 100%;
}

#ecg {
	fill: none;
	stroke: #fff;
	stroke-width: 3;
	stroke-linecap: round;
	stroke-linejoin: miter;
	opacity: 0;
	stroke-dashoffset: 1000;
  stroke-dasharray: 1000;
}
