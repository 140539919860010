html,
body {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body > #frame {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
