@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes shrink {
  to {
    transform: scaleX(.2);
  }
}

@keyframes fadeInBottom {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes ecg {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    stroke-dashoffset: 2000;
  }
  99% {
    opacity: 0;
    stroke-dashoffset: 3000;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}
